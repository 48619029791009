define(['app', '$window'], function(app, $window) {

  const subscriptionBySku = function() {

    const component = {};

    const _config = {
      attrib: {
        widgetName: 'data-block-name',
        widgetId: 'data-widget-id',
        progressCountButtonHref: 'href',
        masterProductPrice: 'data-master-product-price'
      },
      selectors: {
        nextStepButtons: '[data-js-element=subscriptionBySku_selectSkuCta]',
        progressCountButtons: '[data-js-element=subscriptionBySku_progressCount]',
        addToBasketButton: '[data-js-element=subscriptionBySku_addToBasketButton]',
        progressCountRadioButtons: '[data-js-element=subscriptionBySku_radioButton-progressStep]',
        masterProductRadioButtons: '[data-js-element=subscriptionBySku_radioButton-masterProduct]',
        childProductRadioButtons: '[data-js-element=subscriptionBySku_radioButton-childProduct]',
        defaultChildProductLabel: '[data-js-element=subscriptionBySku_childProductLabel-default]',
        shippingInfo: '[data-js-element=subscriptionBySku_shippingInfo]'
      },
      classNames: {
        progressCountActiveClass: 'subscriptionBySku_progressCount-gradientActiveColor'
      },
      windowHashes: {
        nextStep: '#nextStep',
        previousStep: '#previousStep'
      }
    };

    const _init = function(element) {
      component.element = element;
      component.attachListeners();
      component.baseHref = component.element.querySelector(component.config.selectors.addToBasketButton).href;
      component.shippingInfo = component.element.querySelector(component.config.selectors.shippingInfo).innerText;
      component.updateShippingInfo();
      component.updateChildSelections();
      component.checkWindowLocationHash();

      return component;
    };

    const _attachListeners = () => {
      const addToBasketButton = component.element.querySelector(component.config.selectors.addToBasketButton);
      const nextStepButtons = component.element.querySelectorAll(component.config.selectors.nextStepButtons);
      const progressCountButtons = component.element.querySelectorAll(component.config.selectors.progressCountButtons);
      const masterProductRadioButtons = component.element.querySelectorAll(component.config.selectors.masterProductRadioButtons);
      const childProductRadioButtons = component.element.querySelectorAll(component.config.selectors.childProductRadioButtons);

      Array.from(nextStepButtons).map(el => el.addEventListener('click', component.updateProgressBar));
      Array.from(progressCountButtons).map(el => el.addEventListener('click', component.updateProgressBar));

      Array.from(masterProductRadioButtons).forEach(el => {
        el.addEventListener('click', component.updateMasterProduct);
        el.addEventListener('click', component.updateShippingInfo);
        el.addEventListener('click', component.updateChildSelections);

        if (el.checked) {
          component.masterProductId = el.value;
          component.masterProductPrice = el.getAttribute(component.config.attrib.masterProductPrice);
        }
      });

      Array.from(childProductRadioButtons).map(el => el.addEventListener('click', component.updateHrefChildProduct));
      addToBasketButton.addEventListener('click', component.addToBasket);
    };

    const _addToBasket = (ev) => {
      $window.location = ev.target.href;
    };

    const _updateMasterProduct = (ev) => {
      component.masterProductId = ev.target.value;
      component.masterProductPrice = ev.target.getAttribute(component.config.attrib.masterProductPrice);
    };

    const _updateHrefChildProduct = (ev) => {
      const childProductId = ev.target.value;
      const addToBasketButton = component.element.querySelector(component.config.selectors.addToBasketButton);
      const appendChildProductParam = childProductId !== null ? 'variation1=' + childProductId  : '';

      addToBasketButton.href = `${component.baseHref}?buy=${component.masterProductId}&${appendChildProductParam}`;
    };

    const _updateProgressBar = (ev) => {
      const progressCountRadioButtons = component.element.querySelectorAll(component.config.selectors.progressCountRadioButtons);

      if (ev.target.getAttribute('href') === component.config.windowHashes.nextStep) {
        Array.from(progressCountRadioButtons).map((el) => {
          if (el.id === 'step-2') {
            el.checked = true;
            component.addProgressCountActiveClass();
          }
        });
      }

      if (ev.target.getAttribute('href') === component.config.windowHashes.previousStep) {
        Array.from(progressCountRadioButtons).map((el) => {
          if (el.id === 'step-1') {
            el.checked = true;
            component.removeProgressCountActiveClass();
          }
        });
        component.resetChildSelections();
      }
    };

    const _addProgressCountActiveClass = () => {
      const progressCountButtons = component.element.querySelectorAll(component.config.selectors.progressCountButtons);

      Array.from(progressCountButtons).map((el) => {
        if (el.getAttribute(component.config.attrib.progressCountButtonHref) === component.config.windowHashes.nextStep) {
          el.classList.add(component.config.classNames.progressCountActiveClass);
        }
      });
    };

    const _removeProgressCountActiveClass = () => {
      const progressCountButtons = component.element.querySelectorAll(component.config.selectors.progressCountButtons);

      Array.from(progressCountButtons).map((el) => {
        if (el.getAttribute(component.config.attrib.progressCountButtonHref) === component.config.windowHashes.nextStep) {
          el.classList.remove(component.config.classNames.progressCountActiveClass);
        }
      });
    };

    const _updateShippingInfo = () => {
      const shippingPrice = component.masterProductPrice ? component.masterProductPrice : "";
      const shippingInfoElement = component.element.querySelector(component.config.selectors.shippingInfo);

      shippingInfoElement.innerText = `${shippingPrice} ${component.shippingInfo}`;
    };

    const _checkWindowLocationHash = () => {
      const windowLocationHash = window.location.hash;
      const stepTwoProgressCount = component.element.querySelectorAll(component.config.selectors.progressCountButtons)[1];

      if (windowLocationHash === component.config.windowHashes.nextStep) {
        component.simulateClick(stepTwoProgressCount);
      }
    };

    const _updateChildSelections = () => {
      const defaultChildProductLabel = component.element.querySelector(component.config.selectors.defaultChildProductLabel);

      component.simulateClick(defaultChildProductLabel);
    };

    const _resetChildSelections = () => {
      const childProductRadioButtons = component.element.querySelectorAll(component.config.selectors.childProductRadioButtons);

      Array.from(childProductRadioButtons).map(el => el.checked = false);
    };

    const _simulateClick = (element) => {
      const event = new MouseEvent("click", {
        bubbles: true,
        cancelable: true,
        view: window
      });

      element.dispatchEvent(event);
    };

    component.config = _config;
    component.init = _init;
    component.attachListeners = _attachListeners;
    component.addToBasket = _addToBasket;
    component.updateMasterProduct = _updateMasterProduct;
    component.updateHrefChildProduct = _updateHrefChildProduct;
    component.updateProgressBar = _updateProgressBar;
    component.addProgressCountActiveClass = _addProgressCountActiveClass;
    component.removeProgressCountActiveClass = _removeProgressCountActiveClass;
    component.updateShippingInfo = _updateShippingInfo;
    component.updateChildSelections = _updateChildSelections;
    component.checkWindowLocationHash = _checkWindowLocationHash;
    component.resetChildSelections = _resetChildSelections;
    component.simulateClick = _simulateClick;
    return component;
  };

  return subscriptionBySku;
});

